import invariant from "tiny-invariant";
import type { BrowserTestContext } from "../../../../dev/BrowserTestContext.ts";

export async function accountCreateInteract({ userEvent, canvasElement, step, dom: { within } }: BrowserTestContext) {
    const canvas = within(canvasElement);

    async function fill(label: string, value: string) {
        const input = await canvas.findByLabelText(label);
        invariant(input instanceof HTMLInputElement, `Expected ${label} to be an input element`);
        if (input.value !== "") {
            await userEvent.clear(input);
        }
        await userEvent.type(input, value);
    }

    await step("Fill in the form", async () => {
        await fill("Billing account name", "My billing account");
        await fill("Invoice email", "mock@warren.io");
        await fill("Contact person's phone", "1234567890");
    });

    await step("Select the Omise payment method", async () => {
        const omiseMethod = await canvas.findByText("Credit card (Omise)");
        await userEvent.click(omiseMethod);

        await fill("Name (Min 4 chars)", "John Doe");
        await fill("Card Number", "4242424242425678");
        await fill("Valid thru", "11/27");
        await fill("CVC", "123");
    });

    const createButton = await canvas.findByRole("button", { name: "Create" });
    await userEvent.click(createButton);

    await step("Wait for the account to be created", async () => {
        await canvas.findByText("Payment Methods", undefined, { timeout: 5000 });
        await canvas.findByText("Visa*5678", { exact: false });
    });
}
