import { createLazyFileRoute } from "@tanstack/react-router";
import { BillingEdit } from "../../../../modules/billing/billingAccount/BillingCreate.tsx";

export const Route = createLazyFileRoute("/_main/billing/account/$id/edit")({
    component: Component,
});

function Component() {
    const { id } = Route.useParams();
    const baId = Number(id);
    return <BillingEdit key={baId} baId={baId} />;
}
