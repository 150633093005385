import { useState } from "react";
import { FileTrigger, type FileTriggerProps } from "react-aria-components";
import { WButton } from "../../../components/button/WButton.tsx";
import type { StandardFieldProps } from "../../../components/forms/standardFieldAttributes.tsx";

export interface FileWithSource {
    name: string;
    base64Source: string;
}

export interface FileWithSourceProps extends Pick<StandardFieldProps, "isDisabled">, FileTriggerProps {
    onChange: (file: FileWithSource | undefined) => void;
    onSetError: (msg: string) => void;
    maxFileSizeInMB?: number;
}

export function FileWithSourceInput({
    onChange,
    onSetError,
    isDisabled,
    maxFileSizeInMB = 10,
    ...props
}: FileWithSourceProps) {
    const [file, setFile] = useState<FileWithSource | undefined>(undefined);

    function handleRemove() {
        setFile(undefined);
        onChange(undefined);
    }

    return (
        <>
            <FileTrigger
                {...props}
                onSelect={(list) => {
                    onSetError("");
                    const file = list?.length ? list[0] : undefined;
                    if (file) {
                        const maxSizeInBytes: number = maxFileSizeInMB * 1024 * 1024;
                        if (file.size < maxSizeInBytes) {
                            const reader = new FileReader();
                            reader.readAsDataURL(file);
                            reader.onload = () => {
                                const name = file.name;
                                const base64Source = String(reader.result).split("base64,")[1];
                                const fileWithSource = { name, base64Source };
                                setFile(fileWithSource);
                                onChange(fileWithSource);
                            };
                        } else {
                            onSetError(`Maximum upload file size is ${maxFileSizeInMB}MB`);
                        }
                    }
                }}
            >
                {!file && (
                    <WButton action={undefined} isDisabled={isDisabled}>
                        Select a file
                    </WButton>
                )}
            </FileTrigger>
            {file && (
                <>
                    <span className="pr-2">{file.name}</span> <WButton icon="jp-trash-icon" action={handleRemove} />
                </>
            )}
        </>
    );
}
