import LR from "../../components/LeftRight.module.css";

import { sortBy } from "remeda";
import { LeftRight } from "../../components/LeftRight.tsx";
import { WSelect, type WSelectStyleProps, type WSelectValueProps } from "../../components/forms/WSelect.tsx";
import { useConfig } from "../../config.ts";
import { CountryFlag } from "./CountryFlag.tsx";
import { allCountries, type CountryInfo } from "./locationCountry.ts";

export interface CountrySelectProps extends WSelectValueProps<CountryInfo, string>, WSelectStyleProps {}

export function CountrySelect(props: CountrySelectProps) {
    const { siteCountry } = useConfig();
    const countries = allCountries();

    return (
        <WSelect
            autoFocusSearch
            defaultValueKey={siteCountry}
            getKey={(item) => item.code.iso2}
            items={sortBy(countries, (l) => l.name)}
            itemClassName={LR.item}
            valueClassName={LR.value}
            aria-label="Select country"
            getTextValue={(item) => item.name}
            searchItems={(query, items) =>
                items.filter((item) => item.name.toLowerCase().includes(query.toLowerCase()))
            }
            {...props}
        >
            {({ name, code: { iso3 } }) => <LeftRight icon={<CountryFlag code={iso3} />} title={name} />}
        </WSelect>
    );
}
